import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

function isInvalid(control: AbstractControl) {
  return control.invalid;
}

function helper(form: UntypedFormGroup) {
  Object.keys(form.controls).forEach((field) => {
    const control = form.get(field);
    if (isInvalid(control)) {
      control.markAsTouched();
      control.updateValueAndValidity();
    }

    if (
      control instanceof UntypedFormArray ||
      control instanceof UntypedFormGroup
    ) {
      helper(control as UntypedFormGroup);
    }
  });

  showError();
}

function helperControl(control: UntypedFormControl) {
  if (isInvalid(control)) {
    control.markAsTouched();
    control.updateValueAndValidity();
  }

  if (
    control instanceof UntypedFormArray ||
    control instanceof UntypedFormGroup
  ) {
    helper(control as UntypedFormGroup);
  }

  showError();
}

function showError() {
  setTimeout(() => {
    const element =
      document.getElementsByClassName('has-error')[0] ||
      document.getElementsByClassName('ant-form-explain')[0] ||
      document.getElementsByClassName('ant-form-item-explain')[0] ||
      document.getElementsByClassName('ant-form-item-has-error')[0] ||
      document.getElementsByClassName('invalid-checkbox')[0] ||
      document.getElementsByClassName('ant-picker-status-error')[0];

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, 0);
}

export function handleFormSubmit(
  forms: UntypedFormGroup[],
  onSuccess: () => any,
  onFailure?: () => void,
) {
  forms = forms.filter((form) => form);
  const someFormInvalid = forms.some((form) => isInvalid(form));

  if (!someFormInvalid) {
    onSuccess();
  } else {
    forms.forEach((form) => {
      if (isInvalid(form)) {
        helper(form);
        if (onFailure) {
          onFailure();
        }
      }
    });
  }
}

export function handleFormControlsSubmit(
  controls: UntypedFormControl[],
  callbackfn: () => any,
) {
  const someControlInvalid = controls.some((control) => control.invalid);

  if (!someControlInvalid) {
    return callbackfn();
  } else {
    controls.forEach((control) => {
      helperControl(control);
    });
  }
}

export function handleControlsSubmit(
  forms: UntypedFormGroup[],
  callbackfn: () => any,
) {
  forms = forms.filter((form) => form);

  const someControlInvalid = forms.some((form) =>
    Object.keys(form.controls).some((key) => isInvalid(form.controls[key])),
  );

  if (!someControlInvalid) {
    return callbackfn();
  } else {
    forms.forEach((form) => {
      helper(form);
    });
  }
}
